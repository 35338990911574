const searchIcon = require<string>('@branding/img/search.png');
const newsIcon = require<string>('@branding/img/news.png');
const marketSentimentIcon = require<string>('@branding/img/market_sentiment.png');
const openOrdersIcon = require<string>('@branding/img/orders.png');
const openPositionsIcon = require<string>('@branding/img/positions.png');
const priceAlertsIcon = require<string>('@branding/img/bell_empty.png');
const priceAlertsDarkIcon = require<string>('@branding/img/bell_empty_dark.png');
const activitiesIcon = require<string>('@branding/img/activities.png');
const closedPositionsIcon = require<string>('@branding/img/positions.png');
const transactionsIcon = require<string>('@branding/img/transactions.png');
const profileIcon = require<string>('@branding/img/profile.png');
const profileIconDark = require<string>('@branding/img/profile.png');
const manageUsersIcon = require<string>('@branding/img/manage_users.svg');

export const Branding = {
  title: 'BBVA CFD Plataforma',
  titleOffline: 'BBVA CFD Plataforma - Offline Mode',

  iconSize: 48,
  searchIcon,
  newsIcon,
  marketSentimentIcon,
  openOrdersIcon,
  openPositionsIcon,
  priceAlertsIcon,
  priceAlertsDarkIcon,
  activitiesIcon,
  closedPositionsIcon,
  transactionsIcon,
  profileIcon,
  profileIconDark,
  manageUsersIcon,

  primaryColor: '#2e4fa0',
  primaryColorDarkMode: '#7bbafb',
  headerTextColor: '#384047',
  headerBackgroundColorDarkMode: '#05142a',
  backgroundColorDarkMode: '#05142a',
  borderColorDarkMode: '#072146',
  tabsBackgroundColorDarkMode: '#05142a',
  chartBackgroundColor: '#131722',
  iconColor: '#237aba',

  closeAllButtonColor: 'white',
  closeAllButtonColorDarkMode: 'white',
  closeAllButtonBackgroundColor: '#2196f3',
  closeAllButtonBackgroundColorDarkMode: '#02a5a5',

  /* Corporate actions */
  corporateActionTaskEligibleBackgroundColor: '#5da25d',
  corporateActionTaskMaybeEligibleBackgroundColor: '#d45f5f',
  corporateActionTaskEmptyBackgroundColor: '#333333',
  corporateActionTaskNotEligibleBackgroundColor: '#222222',
  corporateActionTaskImportedAndEditedColor: '#5da25d',
  corporateActionTaskSourceBackgroundColor: '#9f6ebf',
  corporateActionTaskManualColor: '#9f6ebf',
  corporateActionTaskAmendColor: '#5da25d',

  tradingIdeasDateColor: '#b2b8bf',
  tradingIdeasDateColorDarkMode: '#909090',
  tradingIdeasDisclaimerDividerColor: '#b2b8bf',
  tradingIdeasDisclaimerDividerColorDarkMode: '#909090',

  tradingJournalGridLinesColor: '#dfdfdf',
  tradingJournalGridLinesColorDarkMode: '#272727',

  spreadBackgroundColorDarkMode: '#05142a',

  chartBackgroundColorDarkMode: '#020B18',

  sentiments: {
    long: '#2E4FA0',
    longDarkMode: '#7DC52E',
    short: '#7BBAFB',
    shortDarkMode: '#AB245C',
  },

  layouts: {
    header: {
      backgroundColor: '#e4ecf1',
      backgroundColorDarkMode: '#072146',

      itemColor: '#696a6e',
      itemColorDarkMode: '#fff',

      itemColorHover: '#7bbafb',

      itemColorActive: '#2e4fa0',
      itemColorActiveDarkMode: '#7bbafb',
    },
    menu: {
      listSeparatorColor: '#e4ecf1',
      listSeparatorColorDarkMode: '#333'
    }
  },

  submitWarningIconColor: '#2e4fa0',
  submitWarningIconColorDarkMode: '#49a5e6'
};